import React from 'react'

let vector = [0]

const Mision = function mision ({text_4,text_5}){
    return (
        <section className="mision">
            <div className="content colflex">
                <div className="pt1 colflex">
                    <h2>{text_4.title}</h2>
                    <img src="/img/line.svg" alt="line-vector"/>
                    <p dangerouslySetInnerHTML={{__html: text_4.text}}>
                    </p>
                </div>
                <div className="pt2 colflex">
                    <h2>{text_5.title}</h2>
                    <img src="/img/line.svg" alt="line-vector"/>
                    <p dangerouslySetInnerHTML={{__html: text_5.text}}>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Mision