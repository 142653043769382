import React from 'react'

let vector = [0]

const ModalShare = function modalshare ({closeModal,copyUrl,closeModal_2}){
    return (
        <div
            className="jquery-modal blocker current" 
            style={{display:'none'}} 
            onClick={(e)=>closeModal(e)}
            id={"modalShare"}
        >
            <div 
                className="moda modalShare"  id="modalShare_m"
                style={{opacity: 1,display: "inline-block",position: "relative"}}
            >
                <a
                    className="close-modal" 
                    onClick={()=>closeModal_2()}
                >
                    X
                </a>
                <h4>COMPARTIR EN:</h4>
                <div className="content grid">
                    <a 
                        onClick={()=>copyUrl()} 
                        className="item colflex"
                    >
                        <img src="/img/products/copy.svg" alt=""/>
                        <span>Copiar<br/>enlance</span>
                    </a>
                    <a 
                        href={"https://api.whatsapp.com/send?text=Hola, te comparto este producto "+window.location.href} 
                        target="_blank" 
                        className="item colflex"
                    >
                        <img src="/img/products/wpp.svg" alt=""/>
                        <span>Whatsapp</span>
                    </a>
                    <a 
                        href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href}
                        target="_blank" 
                        className="item colflex"
                    >
                        <img src="/img/products/fb.svg" alt=""/>
                        <span>Facebook</span>
                    </a>
                    <a 
                        href={"https://twitter.com/intent/tweet?url="+window.location.href}
                        target="_blank" 
                        className="item colflex"
                    >
                        <img src="/img/products/tw.svg" alt=""/>
                        <span>Twitter</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ModalShare