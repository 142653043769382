import React from 'react'

let vector = [0]

const Menu = function menu ({info}){
    return (
        <div className="contMob colflex" id="nav-menu">
            <div className="flex close">
                <img src="/img/close.svg" alt="close" id="nav-close"/>
            </div>

            <div className="content colflex">
                <img src="/img/logo.svg" alt="Gutand" className="logob"/>
                <div className="links grid">
                    <a href={'/'}>Inicio</a>
                    <a href={'/nosotros'}>Nosotros</a>
                    <a href={'/portafolio'}>Portafolio</a>
                    <a href={'/contacto'}>Contacto</a>
                </div>
                <div className="social flex">
                    <a href={info.instagram} target='_blank' className="flex">
                        <img src="/img/insta.svg" alt="Ig-icon"/>
                    </a>
                    <a href={info.facebook} target='_blank' className="flex">
                        <img src="/img/face.svg" alt="Fb-icon"/>
                    </a>
                </div>
            </div>

        </div>
    )
}

export default Menu