import React from 'react'

let vector = [0]

const Ally = function ally ({text}){
    return (
        <section className="ally">
            <div className="content colflex">
                <div className="image flex relative">
                    <img src="/img/bg.png" alt="background"/>
                    <img src="/img/worker.png" alt="" className="worker absolute"/>
                    <img src="/img/rcc.png" alt="" className="symbol absolute"/>
                </div>
                <div className="info colflex">
                    <h1 dangerouslySetInnerHTML={{__html: text.title}}></h1>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                    <a href={'/nosotros'} className="flex">
                        <span>Ver más</span>
                        <img src="/img/rightb.svg" alt="➡️"/>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Ally