import React from 'react'

let vector = [0]
let validate = 1

const Services = function services ({text, list}){
    return (
        <section className="servicesH" style={{backgroundImage: 'url('+text.img+')'}}>
            <div className="content colflex relative">
                <h1 dangerouslySetInnerHTML={{__html: text.title}}></h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                <div className="slickServicesH" id="slickServicesH">
                  {list.map((l)=>{
                    return(
                      <div className="item colflex" key={l.id}>
                        <img src={l.img} alt="image-service"/>
                        <span>{l.name}</span>
                      </div>
                    )
                  })}
                </div>
                <img src="/img/backs.svg" alt="⬅️" className="arrow backSer absolute dnone"/>
                <img src="/img/nexts.svg" alt="➡️" className="arrow nextSer absolute dnone"/>
            </div>
            {vector.map((v)=>{
                if(list.length > 0 && validate == 1){
                    validate = 2
                    setTimeout(function(){
                        $('#slickServicesH').slick({
                            slidesToShow: 1,
                            dots: true,
                            arrows: false,
                            autoplay: true,
                            autoplaySpeed: 5000,
                            mobileFirst: true,
                            centerMode: true,
                            variableWidth: true,
                            responsive: [
                              {
                                breakpoint: 760,
                                settings: {
                                  centerMode: false,
                                  variableWidth: false,
                                  slidesToShow: 3
                                }
                              },
                              {
                                breakpoint: 1020,
                                settings: {
                                  dots: false,
                                  centerMode: false,
                                  variableWidth: false,
                                  slidesToShow: 4,
                                }
                              },
                              {
                                breakpoint: 1250,
                                settings: {
                                  slidesToShow: 4,
                                  dots: false,
                                  centerMode: false,
                                  variableWidth: false,
                                  arrows: true,
                                  prevArrow: '.backSer',
                                  nextArrow: '.nextSer'
                                }
                              },
                              {
                                breakpoint: 1900,
                                settings: 'unslick'
                              },
                            ]
                        });
                    },100)
                }
            })}
        </section>
    )
}

export default Services