import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Content from "../components/about/content";
import Mision from "../components/about/mision";
import Values from "../components/about/values";


const rootElement = document.getElementById("content_about");
const root = createRoot(rootElement);

export default class About extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'about',
            info:[],
            text_1:[],
            text_2:[],
            text_3:[],
            text_4:[],
            text_5:[],
            error: null,
        }

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/about')
            let data = await res.json()
            this.setState({
                info:data[0],
                text_1:data[1][3],
                text_2:data[1][4],
                text_3:data[1][5],
                text_4:data[1][8],
                text_5:data[1][9],
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                        text_1={this.state.text_1}
                        text_2={this.state.text_2}
                        text_3={this.state.text_3}
                    />
                    <Mision
                        text_4={this.state.text_4}
                        text_5={this.state.text_5}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_about')) {
    root.render(
        <About />
    )
}