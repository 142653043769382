import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Banner from "../components/index/banner";
import Portfolio from "../components/index/portfolio";
import Ally from "../components/index/ally";
import Services from "../components/index/services";


const rootElement = document.getElementById("content_index");
const root = createRoot(rootElement);

export default class Index extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'index',
            info:[],
            banner:[],
            text_1:[],
            text_2:[],
            text_3:[],
            categories:[],
            services:[],
            error: null,
            loading: 0,
        }

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/home')
            let data = await res.json()
            this.setState({
                info:data[0],
                banner:data[1],
                text_1:data[2][0],
                text_2:data[2][1],
                text_3:data[2][2],
                categories:data[3],
                services:data[4]
            })
            if(data[1].length > 0){
                setTimeout(()=>{
                    this.setState({
                        loading: 1,
                    })
                },2000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <div className={'loading '+(this.state.loading == 1?'loading_none':'')}>
                    <div>
                        <img 
                            className="pulse animated infinite" 
                            src='/img/logo.svg'
                        />
                    </div>
                </div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Banner
                        list={this.state.banner}
                    />
                    <Portfolio
                        text={this.state.text_1}
                        list={this.state.categories}
                    />
                    <Ally
                        text={this.state.text_2}
                    />
                    <Services
                        text={this.state.text_3}
                        list={this.state.services}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_index')) {
    root.render(
        <Index />
    )
}