setTimeout(function(){
    // GET HEIGHT HEADER FOR AUTO SEPARATOR
    const header = document.getElementById('header'),
    sepHeader = document.getElementById('separatorHeader')
    if (header) {
        let heightHeader = header.offsetHeight
        sepHeader.style.height = heightHeader.toString() + 'px'
    }


    /*===== MENU SHOW & HIDDEN =====*/
    const navMenu = document.getElementById('nav-menu'),
    toggleMenu = document.getElementById('nav-toggle'),
    closeMenu = document.getElementById('nav-close')

    /*SHOW*/
    toggleMenu.addEventListener('click', ()=>{
    navMenu.classList.toggle('show')
    })

    /*HIDDEN*/
    closeMenu.addEventListener('click', ()=>{
    navMenu.classList.remove('show')
    })

    // MODALS
    /*
    $('a.').click(function () {
        $(this).modal({
            fadeDuration: 150,
            clickClose: false,
            showClose: false
        });
        return false;
    });
    */

    ///////OPEN ACCORDION////////
    /*
    let acc = document.getElementsByClassName('accTittle'),
        content = document.getElementsByClassName('accContent')

    if (acc){
        for (i=0; i<acc.length; i++){
            acc[i].addEventListener('click', function(){
            this.classList.toggle('rotateImg')
            let content = this.nextElementSibling
            content.classList.toggle('openedContent')
            })
        }
    }
    */

},10)
