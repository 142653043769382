import React from 'react'

let vector = [0]

const Content = function content ({text_1,text_2,text_3}){
    return (
        <section className="about">
            <div className="content colflex">
                <div className="pt1 colflex">
                    <img src="/img/about.jpg" alt="about-image"/>
                    <div className="resume colflex">
                        <h1 dangerouslySetInnerHTML={{__html: text_1.title}}></h1>
                        <p dangerouslySetInnerHTML={{__html: text_1.text}}></p>
                    </div>
                </div>

                <div class="pt2 colflex relative">
                    <div class="text">
                        <p dangerouslySetInnerHTML={{__html: text_2.text}}>
                        </p>
                    </div>
                    <img src={text_2.img} alt="worker-image"/>
                </div>

            </div>
        </section>
    )
}

export default Content