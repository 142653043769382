import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Content from "../components/contact/content";

const rootElement = document.getElementById("content_contact");
const root = createRoot(rootElement);

export default class Contact extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'contact',
            info:[],
            text_1:[],
            form:{
                name: '',
                company: '',
                phone: '',
                mail: '',
                message: '',
                token:'',
            },
            error: null,
        }

        this.handleChangeForm = this.handleChangeForm.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)

        this._recaptchaRef = React.createRef()

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/contact')
            let data = await res.json()
            this.setState({
                info:data[0],
                text_1:data[1][6],
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }


    handleChangeForm(e){
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmit = async (e) => {
        if(
            this.state.form.name == '' ||
            this.state.form.company == '' ||
            this.state.form.phone == '' ||
            this.state.form.mail == ''
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form:{
                    ...this.state.form,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form)
            }
            let res = await fetch(`/api/contact`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form:{
                        name: '',
                        company: '',
                        phone: '',
                        mail: '',
                        message: '',
                        token:'',
                    }
                })
                document.getElementById("formContact").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                        info={this.state.info}
                        text={this.state.text_1}

                        onChange={this.handleChangeForm}
                        form={this.state.form}
                        submit={this.submitClick}
                        recaptchaRef={this._recaptchaRef}

                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_contact')) {
    root.render(
        <Contact />
    )
}