import React from 'react'

let vector = [0]

const Footer = function footer ({info}){
    return (
        <div>
            <a href={"https://api.whatsapp.com/send/?phone="+info.whatsapp} className="floatWpp grid flex">
                <img src="/img/wpp.svg" alt="🌐"/>
            </a>
            <footer>
                <div className="container colflex">
                    <img src="/img/logow.svg" alt="logo-white" className="logoFoo"/>
                    <div className="item colflex">
                        <h3>llámanos</h3>
                        <p>{info.phone_one}</p>
                        <p>{info.phone_two}</p>
                        <p>{info.phone_three}</p>
                        <p>(+57) 3103639168</p>
                        <h3>Escríbenos</h3>
                        <p>{info.mail_one}</p>
                        <p>{info.mail_two}</p>
                    </div>
                    <div className="item colflex">
                        <h3>encuéntranos</h3>
                        <p>{info.address}, {info.city}</p>
                        <h3>Síguenos</h3>
                        <div className="social flex">
                            <a href={info.instagram} target="_blank" className="flex">
                                <img src="/img/insta.svg" alt="Ig-icon"/>
                            </a>
                            <a href={info.facebook} target="_blank" className="flex">
                                <img src="/img/face.svg" alt="Fb-icon"/>
                            </a>
                            </div>
                    </div>
                    <div className="qrInfo colflex">
                        <div className="qr dnone">
                            <p>
                                Escanea el QR<br/>y descarga<br/>nuestro Brochure
                            </p>
                            <img src={"/"+info.qr_brochure} alt="qr-image"/>
                        </div>
                        <div className="brochure colflex">
                            <p>Descarga nuestro Brochure</p>
                            <a href={"/"+info.brochure} target='_blank' className="flex">
                                <img src="/img/down.svg" alt="⬇️"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="gtnd colflex">
                    <p>
                        Radio Comunicaciones Del Caribe. <br/>Todos los derechos <br/>reservados
                    </p>
                    <span>|</span>
                    <div className="mark colflex">
                        <p>Creado por</p>
                        <a href="https://gutand.com/" target='_blank' className="flex">
                            <img src="/img/gtnd.svg" alt="Gutand"/>        
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer