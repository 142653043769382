import React from 'react'

let vector = [0]
import { Fancybox } from "@fancyapps/ui"
let contador = 0
let validate = 1

const Gallery = function gallery ({product,images}){
    return (
        <div 
            className="galleryProperty dnone"
            id="galleryProperty"
        >
            <a 
                href={"/"+product.img}
                data-fancybox="gallery" 
                data-caption="" 
                id='image_first'
            >
                <img src={"/"+product.img} data-fancybox="product" alt="image-product"/>
            </a>
            {images.map((i)=>{
                let url_image = i.img.replace('https://admin.radiocomunicaciones.co/','')
                return(
                    <a 
                        href={"/"+url_image}
                        data-fancybox="gallery" 
                        data-caption="" 
                        key={i.id}
                    >
                        <img src={"/"+url_image} data-fancybox="product" alt="image-product"/>
                    </a>
                )
            })}
            {vector.map((v)=>{
                 $(document).ready(function() {
                    if(images.length > 0  && validate == 1){
                        validate = 2
                        Fancybox.bind("[data-fancybox]", {
                            contentClick: "iterateZoom",
                            Images: {
                            Panzoom: {
                                maxScale: 2,
                            },
                            },
                        });
                    }else if(validate == 1){
                        validate = 3
                    }else if(validate == 3){
                        validate = 4
                        Fancybox.bind("[data-fancybox]", {
                            contentClick: "iterateZoom",
                            Images: {
                            Panzoom: {
                                maxScale: 2,
                            },
                            },
                        });
                    }
                });
            })}
        </div>
    )
}

export default Gallery