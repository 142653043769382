import React from 'react'

let vector = [0]

const Contact = function contact ({text}){
    return (
        <section className="terms">
            <div className="content colflex">
                <h1>{text.title}</h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
            </div>
        </section>
    )
}

export default Contact