import React from 'react'

let vector = [0]
let validate = 1

const Portfolio = function portfolio ({text,list}){
    return (
        <section className="portfolioH" style={{backgroundImage: 'url('+text.img+')'}} >

            <div className="content colflex">
                <div className="tittle flex">
                    <h1>{text.title}</h1>
                    <a href={'/portafolio'} className="flex">
                        <span>Ver todos</span>
                        <img src="/img/rightb.svg" alt="➡️"/>
                    </a>
                </div>

                <div className="itemsPort" id="slickPortH">
                    {list.map((l)=>{
                        return(
                            <div className="item colflex" key={l.id}>
                                <div className="image flex">
                                    <img src={l.icon_mobile} alt="icon"/>
                                    <img src={l.icon_desktop} alt="iccon"/>
                                </div>
                                <span>{l.name}</span>
                                <a href={'/portafolio'} className="arrow dnone">
                                    <img src="/img//rightw.svg" alt=""/>
                                </a>
                            </div>
                        )
                    })}
                </div>

                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                        validate = 2
                        setTimeout(function(){
                            $('#slickPortH').slick({
                                slidesToShow: 2,
                                dots: false,
                                arrows: false,
                                autoplay: true,
                                autoplaySpeed: 5000,
                                mobileFirst: true,
                                centerMode: true,
                                responsive: [
                                  {
                                    breakpoint: 760,
                                    settings: {
                                      slidesToShow: 4,
                                    }
                                  },
                                  {
                                    breakpoint: 1020,
                                    settings: {
                                      slidesToShow: 6,
                                    }
                                  },
                                  {
                                    breakpoint: 1279,
                                    settings: 'unslick'
                                  },
                                ]
                            });
                        },100)
                    }
                })}
      
                <a href={'/portafolio'} className="flex">
                    <span>Ver todos</span>
                    <img src="/img/righta.svg" alt="➡️"/>
                </a>
            </div>

        </section>
    )
}

export default Portfolio