import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Products from "../components/portfolio/products";
import Modal from "../components/portfolio/modal";

const rootElement = document.getElementById("content_portfolio");
const root = createRoot(rootElement);

//pagination configuration
let ITEMS_FOR_PAGES = 6
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

export default class Portfolio extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'portfolio',
            info:[],
            categories:[],
            product:[],
            brands:[],
            subcategories:[],
            //filtros
            category_id: 0,
            subcategory_id: 0,
            brand_id: 0,
            order_id: 0,
            error: null,
            loading: 0,
        }

        this.closeModal = this.closeModal.bind(this)
        this.openModal = this.openModal.bind(this)
        
        this.nextHandler = this.nextHandler.bind(this)
        this.prevHandler = this.prevHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)

        this.getBrandsAndSubs = this.getBrandsAndSubs.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this.changeCategory = this.changeCategory.bind(this)
        this.closeChangeCategory = this.closeChangeCategory.bind(this)
        this.closeAll = this.closeAll.bind(this)

        this.changeOrder_b = this.changeOrder_b.bind(this)
        this.closeChangeOrder = this.closeChangeOrder.bind(this)

        this.changeSubCategory = this.changeSubCategory.bind(this)
        this.closeChangeSubCategory = this.closeChangeSubCategory.bind(this)

        this.changeBrand = this.changeBrand.bind(this)
        this.closeChangeBrand = this.closeChangeBrand.bind(this)

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/portfolio')
            let data = await res.json()

            datosFromApi = data[2]
            pages = data[2].length / ITEMS_FOR_PAGES
            items = [...data[2]].splice(currentPage,ITEMS_FOR_PAGES)

            this.setState({
                info:data[0],
                categories:data[1],
                product:items,
                brands:data[3],
                subcategories:data[4],
            })
            if(data[1].length > 0){
                setTimeout(()=>{
                    this.setState({
                        loading: 1,
                    })
                },2000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    closeModal(){
        $('.jquery-modal').css('display','none')
    }
    openModal(){
        $('.jquery-modal').css('display','inline-block')
    }

    nextHandler(){
        const totalElementos = datosFromApi.length
        const nextPage = currentPage + 1
        const firstIndex = nextPage * ITEMS_FOR_PAGES
        if(firstIndex >= totalElementos) return
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            product:items
        })
        currentPage = nextPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    prevHandler(){
        const prevPage = currentPage - 1    
        if (prevPage < 0) return
        const firstIndex = prevPage * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            product:items
        })
        currentPage = prevPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    changeHandler(id){
        const firstIndex = id * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            product:items
        })
        currentPage = id
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    getBrandsAndSubs  = async(e)=>{
        let res = await fetch('/api/brandsubs/'+
            this.state.category_id
        )
        let data = await res.json()

        this.setState({
            subcategories:data[0],
            brands:data[1]
        })

    }

    getFilter= async(e)=>{

        let res = await fetch('/api/filters/'+
            this.state.category_id+'/'+
            this.state.subcategory_id+'/'+
            this.state.brand_id+'/'+
            this.state.order_id
        )
        let data = await res.json()

        datosFromApi = data
        pages = data.length / ITEMS_FOR_PAGES
        currentPage = 0
        items = [...data].splice(currentPage,ITEMS_FOR_PAGES)
        this.setState({
            product:items
        })

        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    changeCategory = async (id,text) =>{

        $('.accContent').css('display','none')
        $('.accContent_cat_'+id).css('display','block')

        const tag = document.getElementById('filterTag_category')
        const tagSpan = document.getElementById('filterTag_span_category')
        tag.style.display = "flex"
        tagSpan.innerHTML = " "+text

        document.getElementById('filterTag_all').style.display = "flex"

        document.getElementById('filterTag_subcategory').style.display = "none"
        document.getElementById('filterTag_brand').style.display = "none"

        this.setState({
            category_id: id,
            subcategory_id: 0,
            brand_id: 0,
        })

        setTimeout(()=>{
            this.getBrandsAndSubs()
        },10)

        setTimeout(()=>{
            this.getFilter()
        },10)

    }

    closeChangeCategory = async () =>{
        this.setState({
            category_id: 0,
        })
        document.getElementById('filterTag_category').style.display = "none"
        
        setTimeout(()=>{
            this.getFilter()
        },10)
    }

    closeAll = async (e)=>{
        this.setState({
            category_id: 0,
            subcategory_id: 0,
            brand_id: 0,
            order_id: 0
        })

        document.getElementById('filterTag_all').style.display = "none"
        document.getElementById('filterTag_category').style.display = "none"

        setTimeout(()=>{
            this.getFilter()
        },10)
    }

    changeOrder_b = async (id,text) =>{

        const tag = document.getElementById('filterTag_order')
        const tagSpan = document.getElementById('filterTag_span_order')
        tag.style.display = "flex"
        tagSpan.innerHTML = " "+text

        document.getElementById('filterTag_all').style.display = "flex"

        this.setState({
            order_id: id,
        })

        setTimeout(()=>{
            this.getFilter()
        },10)

    }
    
    closeChangeOrder = async () =>{
        this.setState({
            category_id: 0,
        })
        document.getElementById('filterTag_order').style.display = "none"
        
        setTimeout(()=>{
            this.getFilter()
        },10)
    }


    changeSubCategory = async (id,text) =>{

        $('input[type="checkbox"][name="sub_checkbox"]').prop('checked', false)
        let check = document.getElementById("sub_"+id)
        check.checked = true

        const tag = document.getElementById('filterTag_subcategory')
        const tagSpan = document.getElementById('filterTag_span_subcategory')
        tag.style.display = "flex"
        tagSpan.innerHTML = " "+text

        document.getElementById('filterTag_all').style.display = "flex"

        this.setState({
            subcategory_id: id,
        })

        setTimeout(()=>{
            this.getFilter()
        },10)

    }

    closeChangeSubCategory = async () =>{
        this.setState({
            subcategory_id: 0,
        })
        $('input[type="checkbox"][name="sub_checkbox"]').prop('checked', false)
        document.getElementById('filterTag_subcategory').style.display = "none"
        
        setTimeout(()=>{
            this.getFilter()
        },10)
    }

    changeBrand = async (id,text) =>{

        $('input[name="brand_checkbox"]').prop('checked', false)
        let check = document.getElementById("brand_"+id)
        check.checked = true

        const tag = document.getElementById('filterTag_brand')
        const tagSpan = document.getElementById('filterTag_span_brand')
        tag.style.display = "flex"
        tagSpan.innerHTML = " "+text

        document.getElementById('filterTag_all').style.display = "flex"

        this.setState({
            brand_id: id,
        })

        setTimeout(()=>{
            this.getFilter()
        },10)

    }

    closeChangeBrand = async () =>{
        this.setState({
            brand_id: 0,
        })

        $('input[type="checkbox"][name="brand_checkbox"]').prop('checked', false)
        document.getElementById('filterTag_brand').style.display = "none"
        
        setTimeout(()=>{
            this.getFilter()
        },10)
    }
    
    render() {
        return (
            <div>
                <div className={'loading '+(this.state.loading == 1?'loading_none':'')}>
                    <div>
                        <img 
                            className="pulse animated infinite" 
                            src='/img/logo.svg'
                        />
                    </div>
                </div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Products
                        openModal={this.openModal}
                        categories={this.state.categories}

                        product={this.state.product}
                        prevHandler={this.prevHandler}
                        nextHandler={this.nextHandler}
                        currentPage={currentPage}
                        changeHandler={this.changeHandler}
                        pages={pages}

                        changeCategory={this.changeCategory}
                        closeAll={this.closeAll}
                        closeChangeCategory={this.closeChangeCategory}

                        changeOrder={this.changeOrder_b}
                        closeChangeOrder={this.closeChangeOrder}

                        closeChangeSubCategory={this.closeChangeSubCategory}
                        closeChangeBrand={this.closeChangeBrand}
                    />
                    <Modal
                        closeModal={this.closeModal}
                        categories={this.state.categories}
                        subcategories={this.state.subcategories}
                        brands={this.state.brands}

                        changeCategory={this.changeCategory}
                        changeSubCategory={this.changeSubCategory}
                        changeBrand={this.changeBrand}

                        category_id={this.state.category_id}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_portfolio')) {
    root.render(
        <Portfolio />
    )
}